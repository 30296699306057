import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import doubleh from '../../../images/double-horizontal.jpg';
import doublev from '../../../images/double-vertical.jpg';
import arrowIcon from '../../../images/arrow-icon.svg';
import Loader from '../../../components/Loader';

import '../../../styles/double.scss';

const Double = ({location}) => {
    const [ isLoading, setLoading ] = useState(true);

    useEffect(() => {
		if (document.getElementById('model-double'))
			document.getElementById('model-double').addEventListener('load', () => {
				setLoading(false)
			})
    }, []);
    return (
        <Layout currentPath={location}>
			<SEO title="Ínsula Living | Double" />
            <div className="double">
				<Link to="/proyectos/insula-living/tipos" className="back">
					&larr;Volver
				</Link>
				<div className="double__title">
					<div className="double__texture" />
					<h1>DOUBLE</h1>
                    <div className="double__title__vendido-container">
                        <p>VENDIDOS</p>
                    </div>
				</div>
				<div className="double__info-container">
                    <div className="caracteristicas">
                        <div className="caracteristicas__leftbar" />
                        <div className="caracteristicas__title">
                            <p>CARACTERÍSTICAS</p>
                        </div>
                        <div className="caracteristicas__double">
                            <div className="informacion-double">
                                <p>Precio: Desde $170'000.000</p>
                                <p>Area construida: 24.00m&#178;</p>
                                <p>Mueble Mezzanine: 10.03m&#178;</p>
                                <p>Apartamentos: 401 - 801</p>
                            </div>
                        </div>
                        <div className="caracteristicas__rightbar" />
                    </div>

                    <div className="info-general">
                        {/* <div className="info-general__modelo-3d">
                            <div className="title-modelo">
                                <p>MODELO 3D</p>
                            </div>
                            {isLoading ? (
                                <div className="loader-container">
                                    <p>Cargando modelo 3D</p>
                                    <Loader />
                                </div>
                            ) : null}
                            <model-viewer
                                id="model-double"
                                src="../../../Apartamento-501.glb"
                                alt="Ínsula Juananbú"
                                shadow-intensity="1"
                                camera-controls
                                interaction-prompt="auto"
                                ar
                                auto-rotate
                                magic-leap
                                loading="eager"
                                camera-orbit="0deg 0deg 85%"
                            />
                        </div> */}

                        <div className="info-general__plano">
                            <div className="title-plano">
                                <p>PLANO</p>
                            </div>
                            <img className="plano-double-horizontal" src={doubleh} alt="Plano Hobbie y Workspot"/>
                            <img className="plano-double-vertical" src={doublev} alt="Plano Hobbie y Workspot"/>
                        </div>

                        {/* <div className="info-general__btn-plano">
                            <p>Ver modelo 3D</p>
                            <img src={arrowIcon} alt="Grupo Ínsula"/>
                        </div> */}
                    </div>
				</div>
			</div>
			
		</Layout>
    );
};

export default Double;